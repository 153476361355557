/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback } from 'react';
import { Dictionary, keyBy } from 'lodash';

import { MdfsSeparated } from 'api/mdfBlocks/types';
import { isMiniMemberArray } from 'components/mdfEditor/fields/relation/relation-utils';
import { getSubMdf, hasPermission, shouldShowField } from 'features/mdf/mdf-utils';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { User } from 'types';
import type { MiniMember, OrderWithMdf } from 'types/forms/forms';
import {
  FieldTypeEnum,
  type LayoutSettings,
  type Mdf,
  type MdfField,
  type MemberType,
  MemberTypeEnum,
  type Metadata,
  type OptionList,
} from 'types/graphqlTypes';
import getMembersFromRelationField from 'utils/mdf/getMembersFromRelationField';

const emptyUserMeta = '{"email": "", "phone": ""}';

const useCopyUtils = () => {
  const { isoToLocaleShort } = useCustomDateTimeUtils();

  const getSingleFieldContent = useCallback(
    (label: string, value: string | number) =>
      `<b>${label.charAt(0).toUpperCase() + label.slice(1)}:</b> ${value}`,
    [],
  );

  const getUserContent = useCallback(
    (
      args:
        | {
            member: MemberType | undefined;
            singleLine?: boolean;
            optionsLists?: OptionList[];
          }
        | {
            member: MemberType | undefined;
            singleLine?: boolean;
            fieldsToPrint: string;
            contactSettingsMap: Dictionary<LayoutSettings>;
            contactMdf: Mdf;
            groups: string[];
            subMdfs: Mdf[];
            contacts: MemberType[];
            relationMembers: MemberType[];
            mdfsSeparated: MdfsSeparated;
            optionLists: OptionList[];
            getMemberTitle: (userId: string) => string | undefined;
            getMember: (userId: string) => User | undefined;
          },
    ) => {
      const { member, singleLine = false } = args;
      let memberMeta: Metadata = {};

      try {
        memberMeta = JSON.parse(member?.metadata ?? emptyUserMeta) as Metadata;
      } catch (e) {
        //
      }
      if (member?.mType === MemberTypeEnum.Contact && 'fieldsToPrint' in args) {
        const {
          fieldsToPrint,
          contactMdf,
          contactSettingsMap,
          contacts,
          groups,
          subMdfs,
          relationMembers,
          mdfsSeparated,
          getMemberTitle,
          getMember,
          optionLists,
        } = args;

        const subTypes = keyBy(subMdfs, (mdf) => mdf.label);

        const shouldShowAllFields = fieldsToPrint === 'all';
        const printableContactFields = new Set(
          fieldsToPrint.split(',').map((field) => field.trim()),
        );

        const visibleFields = contactMdf.fields?.filter((f) =>
          shouldShowField(
            f,
            contactSettingsMap,
            contactSettingsMap,
            false,
            hasPermission(contactMdf?.permissions?.read[f.fieldId], groups),
          ),
        );

        const val = visibleFields
          .map((field) => {
            if (!shouldShowAllFields && !printableContactFields.has(field.fieldId)) return '';

            const fieldValue = memberMeta[field.fieldId]?.toString() ?? '';
            if (!fieldValue || fieldValue === '') return '';

            if (field.type === FieldTypeEnum.subtype) {
              const subMdf = getSubMdf(field, memberMeta, subTypes);

              const subMdfFields = shouldShowAllFields
                ? subMdf?.fields
                : subMdf?.fields?.filter((f) => printableContactFields.has(f.fieldId));

              return getBlockContent({
                orders: [],
                groups,
                getMemberTitle,
                getMember,
                relationMembers,
                contacts,
                contactFieldsToPrint: fieldsToPrint,
                mdfsSeparated,
                subMdfs,
                fields: subMdfFields,
                layoutSettings: subMdf?.views.default,
                permissions: subMdf?.permissions,
                blockTitle: fieldValue,
                metadata: memberMeta,
                isContactInfo: true,
                optionLists,
              });
            }

            if ([FieldTypeEnum.text, FieldTypeEnum.number].includes(field.type)) {
              return getSingleFieldContent(
                contactSettingsMap[field.fieldId].label,
                memberMeta[field.fieldId]?.toString() ?? '',
              );
            }

            return getFieldContent({
              field,
              settingsMap: contactSettingsMap,
              metadata: memberMeta,
              getMemberTitle,
              getMember,
              relationMembers,
              contacts,
              contactFieldsToPrint: fieldsToPrint,
              mdfsSeparated,
              subMdfs,
              groups,
              isContactInfo: true,
              optionLists,
            });
          })
          .filter(Boolean)
          .join('<br>');

        return val.trim();
      }

      const emailString = memberMeta.email ? ` <b>Email:</b> ${memberMeta.email as string}` : '';
      const phoneString = memberMeta.phone ? ` <b>Phone:</b> ${memberMeta.phone as string}` : '';

      if (singleLine) {
        return `${
          member?.mTitle
        } [${member?.mType?.toUpperCase()}]${emailString}${phoneString}`.trim();
      }

      return `<b>Name:</b> ${member?.mTitle} [${member?.mType?.toUpperCase()}]${
        memberMeta?.email ? '<br><b>Email:</b> ' + JSON.stringify(memberMeta.email) : ''
      }
  ${memberMeta?.phone ? '<br><b>Phone:</b> ' + JSON.stringify(memberMeta.phone) : ''}`.trim();
    },
    [],
  );

  const getUserFieldContent = ({
    fieldValue,
    getMember,
    getMemberTitle,
    relationMembers,
    contactFieldsToPrint,
    contacts,
    mdfsSeparated,
    subMdfs,
    groups,
    label,
    optionLists,
  }: {
    fieldValue: string;
    getMemberTitle: (userId: string) => string | undefined;
    getMember: (userId: string) => User | undefined;
    relationMembers: MemberType[];
    contacts: MemberType[];
    contactFieldsToPrint: string;
    mdfsSeparated: MdfsSeparated;
    optionLists: OptionList[];
    subMdfs: Mdf[];
    groups: string[];
    label: string;
  }) => {
    const user = getMember(fieldValue) ?? contacts.find((contact) => contact.mId === fieldValue);

    if (!user) return `<b>${label}:</b> None`;
    const userName = getMemberTitle(fieldValue);

    if (user.mType === MemberTypeEnum.Department || user.mType === MemberTypeEnum.Team) {
      const userValue = getUserContent({
        member: user,
        singleLine: false,
      });

      return `<b>${label}:</b> ${userValue ?? userName ?? ''} `;
    }

    if (user.mType === MemberTypeEnum.Contact) {
      const contactMdf = mdfsSeparated.defaults.find((mdf) =>
        mdf.id.toLowerCase().includes('contact'),
      );

      if (contactMdf) {
        const contactSettingsMap = keyBy(contactMdf.views.default, (setting) => setting.fieldId);

        const userValue = getUserContent({
          member: user,
          contactSettingsMap,
          contactMdf,
          groups,
          subMdfs,
          contacts,
          relationMembers,
          mdfsSeparated,
          fieldsToPrint: contactFieldsToPrint,
          getMember,
          getMemberTitle,
          optionLists,
        });

        if (!userValue) return '';
        return `<b>${label}:</b><br>${userValue}`;
      }
    }

    if (!userName) return '';
    return `<b>${label}:</b> ${userName} `;
  };

  const getRelationFieldContent = ({
    field,
    getMember,
    getMemberTitle,
    relationMembers,
    contacts,
    contactFieldsToPrint,
    mdfsSeparated,
    subMdfs,
    groups,
    metadata,
    optionLists,
    isContactInfo = false,
  }: {
    field: MdfField;
    getMember: (userId: string) => User | undefined;
    getMemberTitle: (userId: string) => string | undefined;
    relationMembers: MemberType[];
    contacts: MemberType[];
    contactFieldsToPrint: string;
    mdfsSeparated: MdfsSeparated;
    subMdfs: Mdf[];
    groups: string[];
    metadata: Metadata;
    isContactInfo: boolean;
    optionLists: OptionList[];
  }) => {
    const actualFieldValue = (metadata[field.fieldId] as MiniMember[]) ?? [];

    const hasContactField = !isMiniMemberArray(actualFieldValue)
      ? false
      : actualFieldValue?.some((f) => f.type === MemberTypeEnum.Contact);

    const listOfRelationalMembers = hasContactField
      ? [...(relationMembers ?? []), ...(contacts ?? [])]
      : [...(relationMembers ?? [])];

    const fieldMembers = getMembersFromRelationField(actualFieldValue, listOfRelationalMembers);

    const getContactContent = (fMember: MemberType, index: number) => {
      const member =
        getMember(fMember?.mId as string) ??
        contacts.find((contact) => contact.mId === fMember?.mId);
      const contactMdf = mdfsSeparated.defaults.find((mdf) =>
        mdf.id.toLowerCase().includes('contact'),
      );

      const defaultContactContent = `${fMember?.mTitle ?? 'Untitled'} (${
        fMember?.mType?.toUpperCase() ?? 'Unknown type'
      })`;

      if (member?.mType === MemberTypeEnum.Contact && contactMdf && member) {
        const contactSettingsMap = keyBy(contactMdf.views.default, (setting) => setting.fieldId);

        const userValue = getUserContent({
          member,
          contactSettingsMap,
          contactMdf,
          groups,
          subMdfs,
          contacts,
          relationMembers,
          mdfsSeparated,
          fieldsToPrint: contactFieldsToPrint,
          getMember,
          getMemberTitle,
          optionLists,
        });

        if (!userValue) return defaultContactContent;

        const value = `${userValue}${index === fieldMembers.length - 1 ? '' : ','}`;

        return isContactInfo ? value : `${defaultContactContent} <br> ${value}`;
      }

      return defaultContactContent;
    };

    const getUserOrGroupContent = (fMember: MemberType, index: number) => {
      const member =
        getMember(fMember?.mId as string) ??
        contacts.find((contact) => contact.mId === fMember?.mId);

      const userValue = getUserContent({ member, singleLine: true });

      return `${userValue}${index === fieldMembers.length - 1 ? '' : ','}`;
    };

    return fieldMembers
      .map((fMember, index) => {
        if (fMember?.mType === MemberTypeEnum.Contact) {
          return getContactContent(fMember, index);
        }

        if (
          [MemberTypeEnum.User, MemberTypeEnum.Department, MemberTypeEnum.Team].includes(
            fMember?.mType as MemberTypeEnum,
          )
        ) {
          return getUserOrGroupContent(fMember, index);
        }

        return `${fMember?.mTitle ?? 'Untitled'} (${
          fMember?.mType?.toUpperCase() ?? 'Unknown type'
        })${index === fieldMembers.length - 1 ? '' : ','}`;
      })
      .filter(Boolean)
      .join('<br>')
      .trim();
  };

  const getFieldContent = useCallback(
    ({
      field,
      settingsMap,
      metadata,
      getMemberTitle,
      getMember,
      relationMembers,
      contacts,
      contactFieldsToPrint,
      mdfsSeparated,
      subMdfs,
      groups,
      optionLists,
      isContactInfo = false,
    }: {
      field: MdfField;
      settingsMap: Record<string, LayoutSettings>;
      metadata: Metadata;
      getMemberTitle: (userId: string) => string | undefined;
      getMember: (userId: string) => User | undefined;
      relationMembers: MemberType[];
      contacts: MemberType[];
      contactFieldsToPrint: string;
      mdfsSeparated: MdfsSeparated;
      subMdfs: Mdf[];
      groups: string[];
      optionLists: OptionList[];
      isContactInfo: boolean;
    }): string => {
      const label = settingsMap[field.fieldId].label;
      const fieldValue = metadata[field.fieldId]?.toString() ?? '';

      if (!fieldValue || fieldValue.toString() === '') return '';

      switch (field.type) {
        case FieldTypeEnum.text:
        case FieldTypeEnum.number:
        case FieldTypeEnum.link:
          return `<b>${label}:</b> ${fieldValue} `;

        case FieldTypeEnum.user:
          return getUserFieldContent({
            fieldValue,
            label,
            getMember,
            getMemberTitle,
            groups,
            relationMembers,
            contacts,
            contactFieldsToPrint,
            mdfsSeparated,
            subMdfs,
            optionLists,
          });

        case FieldTypeEnum.treechoice: {
          const formattedValue = fieldValue.replace(/,/g, ' -> ');
          if (!formattedValue) return '';
          return `<b>${label}:</b> ${formattedValue} `;
        }

        case FieldTypeEnum.checkbox: {
          const isChecked = fieldValue === 'true';
          const checkboxLabel = isChecked ? `[x] ${label}` : `[ ] ${label}`;
          return `<b>${label}:</b> ${checkboxLabel} `;
        }

        case FieldTypeEnum.date: {
          const formattedDate = fieldValue ? isoToLocaleShort(fieldValue, true) : '';
          if (!formattedDate) return '';
          return `<b>${label}:</b> ${formattedDate} `;
        }
        case FieldTypeEnum.choice: {
          const optionList = optionLists.find((list) => list.id === field.optionListId);
          const alternatives =
            (optionList?.optionListType === 'choice' && optionList?.alternatives) ||
            field.alternatives;
          const alternativeLabel = alternatives?.find((alt) => alt.value === fieldValue)?.label;
          if (!fieldValue || fieldValue.toString() === '') return '';
          return `<b>${label}:</b> ${alternativeLabel ?? fieldValue} `;
        }

        case FieldTypeEnum.multiplechoice: {
          const optionList = optionLists.find((list) => list.id === field.optionListId);
          const alternatives =
            (optionList?.optionListType === 'choice' && optionList.alternatives) ||
            field.alternatives;

          const formattedChoices =
            fieldValue !== ''
              ? fieldValue
                  .split(',')
                  .map((fValue) => {
                    const alternative = alternatives?.find((alt) => alt.value === fValue);
                    return alternative?.label ?? fValue;
                  })
                  .join(', ')
              : '';

          if (!formattedChoices) return '';

          return `<b>${label}:</b> ${formattedChoices}`;
        }

        case FieldTypeEnum.relation: {
          const fieldContent = getRelationFieldContent({
            field,
            getMember,
            getMemberTitle,
            relationMembers,
            contacts,
            contactFieldsToPrint,
            mdfsSeparated,
            subMdfs,
            groups,
            metadata,
            isContactInfo,
            optionLists,
          });

          if (!fieldContent) return '';

          return `<b>${label}:</b><br> ${fieldContent}`;
        }

        default:
          return `<b>${label}:</b> ${fieldValue} `;
      }
    },
    [],
  );

  const getOrderContent = useCallback(
    ({
      order,
      groups,
      getMember,
      getMemberTitle,
      index,
      subMdfs,
      subOrders,
      relationMembers,
      contacts,
      contactFieldsToPrint,
      mdfsSeparated,
      optionLists,
      title = 'Task',
    }: {
      order: OrderWithMdf;
      groups: string[];
      getMemberTitle: (userId: string) => string | undefined;
      getMember: (userId: string) => User | undefined;
      index: number;
      subMdfs: Mdf[];
      subOrders: OrderWithMdf[];
      relationMembers: MemberType[];
      contacts: MemberType[];
      optionLists: OptionList[];
      contactFieldsToPrint: string;
      mdfsSeparated: MdfsSeparated;
      title?: string;
    }): string => {
      const { metadata, mdf } = order;
      const subTypes = keyBy(subMdfs, (subMdf) => subMdf.label);

      const settingsMap = keyBy(mdf.views.default, (setting) => setting.fieldId);

      const visibleFields = mdf.fields?.filter((f) =>
        shouldShowField(
          f,
          settingsMap,
          settingsMap,
          false,
          hasPermission(mdf?.permissions?.read[f.fieldId], groups),
        ),
      );

      const createdByUser = getMember(order.mCreatedById);
      const assigneeUser = getMember(order.mAssignee ?? '');

      const subOrdersContent: string = subOrders?.length
        ? subOrders
            .map((subOrder, subIndex) =>
              getOrderContent({
                order: subOrder,
                groups,
                index: subIndex,
                title: 'Sub task',
                subMdfs,
                getMemberTitle,
                getMember,
                relationMembers,
                contacts,
                contactFieldsToPrint,
                mdfsSeparated,
                subOrders,
                optionLists,
              }),
            )
            .join('<br>') ?? ''
        : '';

      const orderInfo = `<b>Created by:</b> ${getUserContent({
        member: createdByUser,
      })}<br><b>Assignee:</b> <br>${assigneeUser ? getUserContent({ member: assigneeUser }) : ''}`;

      return `<br><b>${title} ${index + 1}</b><br>${orderInfo}<br>${
        visibleFields
          ?.map((field) => {
            if (field.type === FieldTypeEnum.subtype) {
              const subMdf = getSubMdf(field, metadata, subTypes);
              const fieldValue = metadata[field.fieldId]?.toString() ?? '';

              if (!fieldValue || fieldValue === '') return '';

              return getBlockContent({
                fields: subMdf?.fields,
                layoutSettings: subMdf?.views.default,
                permissions: subMdf?.permissions,
                metadata,
                blockTitle: fieldValue,
                groups,
                getMemberTitle,
                getMember,
                relationMembers,
                contacts,
                contactFieldsToPrint,
                mdfsSeparated,
                subMdfs,
                optionLists,
              });
            }

            return getFieldContent({
              field,
              settingsMap,
              metadata,
              getMemberTitle,
              getMember,
              relationMembers,
              contacts,
              contactFieldsToPrint,
              mdfsSeparated,
              subMdfs,
              groups,
              isContactInfo: false,
              optionLists,
            });
          })
          .filter(Boolean)
          .join('<br>') ?? ''
      }<br>${subOrdersContent}`;
    },
    [],
  );

  const getBlockContent = useCallback(
    ({
      root = false,
      orders,
      groups,
      getMemberTitle,
      getMember,
      relationMembers,
      contacts,
      contactFieldsToPrint,
      mdfsSeparated,
      subMdfs,
      fields,
      layoutSettings,
      permissions,
      metadata,
      blockTitle,
      optionLists,
      isContactInfo = false,
    }: {
      orders?: OrderWithMdf[];
      groups: string[];
      getMemberTitle: (userId: string) => string | undefined;
      getMember: (userId: string) => User | undefined;
      relationMembers: MemberType[];
      contacts: MemberType[];
      contactFieldsToPrint: string;
      mdfsSeparated: MdfsSeparated;
      optionLists: OptionList[];
      subMdfs: Mdf[];
      fields?: MdfField[];
      layoutSettings?: LayoutSettings[];
      permissions?: Mdf['permissions'];
      metadata: Metadata;
      blockTitle?: string;
      isContactInfo?: boolean;
      root?: boolean;
    }): string => {
      const subTypes = keyBy(subMdfs, (subMdf) => subMdf.label);

      const settingsMap = keyBy(layoutSettings, (setting) => setting.fieldId);

      const visibleFields = fields?.filter((f) =>
        shouldShowField(
          f,
          settingsMap,
          settingsMap,
          false,
          hasPermission(permissions?.read[f.fieldId] as string[], groups),
        ),
      );

      let fieldContent = '';
      if (visibleFields) {
        // Filter out fields that have no value
        const visibleFieldsWithValues = visibleFields.filter(
          (field) =>
            metadata[field.fieldId] !== undefined &&
            metadata[field.fieldId] !== null &&
            metadata[field.fieldId]?.toString() !== '',
        );

        fieldContent =
          visibleFieldsWithValues
            ?.map((field) => {
              if (field.type === FieldTypeEnum.subtype) {
                const subMdf = getSubMdf(field, metadata, subTypes);
                const fieldValue = metadata[field.fieldId]?.toString() ?? '';

                const subContent = getBlockContent({
                  fields: subMdf?.fields,
                  layoutSettings: subMdf?.views.default,
                  permissions: subMdf?.permissions,
                  metadata,
                  blockTitle: fieldValue,
                  groups,
                  getMemberTitle,
                  getMember,
                  relationMembers,
                  contacts,
                  contactFieldsToPrint,
                  mdfsSeparated,
                  subMdfs,
                  isContactInfo,
                  optionLists,
                });

                return `${subContent}`;
              }

              return getFieldContent({
                field,
                settingsMap,
                metadata,
                getMemberTitle,
                getMember,
                relationMembers,
                contacts,
                contactFieldsToPrint,
                mdfsSeparated,
                subMdfs,
                groups,
                isContactInfo,
                optionLists,
              });
            })
            ?.join('<br>') ?? '';
      }

      const orderContent = orders?.length
        ? orders
            ?.map((order, index) => {
              return getOrderContent({
                order,
                groups,
                getMemberTitle,
                index,
                subMdfs,
                getMember,
                contacts,
                contactFieldsToPrint,
                mdfsSeparated,
                subOrders: [],
                relationMembers,
                optionLists,
              });
            })
            .join('<br>')
        : '';

      let orderSection = '';
      if (orders?.length) {
        orderSection = '<br><br><b>Tasks</b>';
        if (orderContent) {
          orderSection += `${orderContent}`;
        }
      }

      if (isContactInfo) {
        return `<div style="padding-left: 8px">${fieldContent ?? ''}${orderSection}</div>`;
      }

      if (!root) {
        return `<b>${blockTitle}</b><br><div style="padding-left: 8px">${fieldContent ?? ''}</div>`;
      }

      return `<h3>${blockTitle}</h3>${fieldContent ?? ''}${orderSection}`;
    },
    [],
  );

  return { getBlockContent, getOrderContent };
};

export default useCopyUtils;
