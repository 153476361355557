import { useEffect, useMemo, useState } from 'react';
import { ColumnDef, TableMeta } from '@tanstack/react-table';
import { atom, useAtom } from 'jotai';
import { Dictionary, keyBy, noop } from 'lodash';
import type { ParsedUser, User } from 'types';

import useAddUserToOrganization from 'api/organization/useAddUserToOrganization';
import useGetOrganizationUsers from 'api/organization/useGetOrganizationUsers';
import useModifyUserInOrganization from 'api/organization/useModifyUserInOrganization';
import Dialog from 'components/dialogs/DialogBuilder';
import Divider from 'components/divider';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip/Tooltip';
import { useAuthContext } from 'contexts/AuthContext';
import { DataTable } from 'features/grids/common/DataTable';
import { useDataTable } from 'features/grids/common/useDataTable';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import useCalculatePolicies from 'hooks/useCalculatePolicies';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { CheckboxWithLabel } from 'lib/checkbox';
import { useCurrentOrganization } from 'screens/main/components/header/navbar/settings/atomsTs';
import { useUsers } from 'store/members';
import type { MemberType, Metadata, OrganizationUser } from 'types/graphqlTypes';

import useIsSuperUser from '../../useIsSuperUser';

interface UserData extends ParsedUser, Pick<OrganizationUser, 'isArchived' | 'isDisabled'> {
  isAdded?: boolean;
}

const groupSelectionAtom = atom<UserData | undefined>(undefined);
const useGroupSelection = () => useAtom(groupSelectionAtom);

const parseUser = (member: User): ParsedUser => {
  const user: ParsedUser = { ...member, metadata: {} };
  try {
    user.metadata = JSON.parse(member.metadata ?? '{}') as Metadata;
  } catch (err) {
    // silently fail
  }
  return user;
};

function ActionCell({ user, disabled }: Readonly<{ user: UserData; disabled: boolean }>) {
  const [currentOrganization] = useCurrentOrganization();
  const { addUserToOrganization, loading: addLoading } = useAddUserToOrganization();
  const { modifyUserInOrganization, loading: modifyLoading } = useModifyUserInOrganization();

  const toggleUserIntoOrganization = (val: boolean) => {
    if (!currentOrganization) return;
    if (val) {
      if (user.isArchived) {
        modifyUserInOrganization(user.mId, currentOrganization.id, undefined, false).catch(noop);
      } else {
        addUserToOrganization(user.mId, currentOrganization?.id, user.groups).catch(noop);
      }
    } else {
      modifyUserInOrganization(user.mId, currentOrganization.id, undefined, true).catch(noop);
    }
  };

  return (
    <LWCheckbox
      setValue={toggleUserIntoOrganization}
      selected={user.isAdded ?? false}
      disabled={disabled || addLoading || modifyLoading}
    />
  );
}

function Groups({
  user,
  disabled,
  groupMap,
}: Readonly<{
  user: UserData;
  disabled: boolean;
  groupMap?: Dictionary<User>;
}>) {
  const [, setGroup] = useGroupSelection();
  const onClick = () => {
    if (!user.isAdded || disabled) return;
    setGroup(user);
  };

  const groupText = user.groups?.map((group) => groupMap?.[group]?.mTitle ?? group).join(', ');

  return (
    <Tooltip title={groupText || 'Unassigned'}>
      <HStack
        onClick={onClick}
        cursor={user.isAdded && !disabled ? 'pointer' : 'not-allowed'}
        width="100%"
        height="100%"
      >
        {user.groups?.length ? (
          <Text truncate>{groupText}</Text>
        ) : (
          <Text color="statusWarning">Unassigned</Text>
        )}
      </HStack>
    </Tooltip>
  );
}

const columns: ColumnDef<UserData>[] = [
  {
    header: 'Active',
    accessorKey: 'isAdded',
    cell: ({ row, table }) => (
      <ActionCell user={row.original} disabled={table.options.meta?.disabled ?? false} />
    ),
    id: 'actions',
    size: 30,
  },
  {
    accessorKey: 'mTitle',
    header: 'Name',
  },

  {
    header: 'Groups',
    accessorKey: 'groups',
    cell: ({ row, table }) => (
      <Groups
        user={row.original}
        disabled={table.options.meta?.disabled ?? false}
        groupMap={table.options.meta?.userMap}
      />
    ),
  },
];

function GroupsSelector({
  groupPolicies,
  modifyUserGroups,
}: Readonly<{
  groupPolicies: MemberType[];
  modifyUserGroups: (userId: string, groups: string[]) => Promise<void>;
}>) {
  const [user, setUser] = useGroupSelection();
  const [groups, setGroups] = useState(user?.groups ?? []);
  const handleClose = () => {
    setGroups([]);
    setUser(undefined);
  };

  useEffect(() => {
    setGroups(user?.groups ?? []);
  }, [JSON.stringify(user?.groups)]);

  const handleSave = () => {
    if (user?.mId) modifyUserGroups(user.mId, groups).catch(noop);
    handleClose();
  };

  const allChecked = useMemo(
    () => groupPolicies.every((g) => groups.includes(g.mRefId!)),
    [groupPolicies, groups],
  );

  const toggleAll = () => {
    if (allChecked) setGroups([]);
    else setGroups(groupPolicies.map((g) => g.mRefId!));
  };

  const indeterminate = !allChecked && groups.length !== 0;

  return (
    <Dialog open={Boolean(user)} onClose={handleClose}>
      <Dialog.Header>Assign to groups</Dialog.Header>
      <Dialog.Body>
        <VStack gap="4px">
          <CheckboxWithLabel
            indeterminate={indeterminate}
            label="All"
            onClick={toggleAll}
            checked={allChecked}
          />
          <Divider style={{ width: '100%' }} />
          <VStack gap="4px">
            {groupPolicies.map((item) => {
              return (
                <CheckboxWithLabel
                  key={item.mRefId}
                  label={item.mTitle ?? item.mRefId!}
                  checked={groups.includes(item.mRefId!)}
                  onClick={() => {
                    if (groups.includes(item.mRefId!)) {
                      setGroups(groups.filter((g) => g !== item.mRefId));
                    } else {
                      setGroups([...groups, item.mRefId!]);
                    }
                  }}
                />
              );
            })}
          </VStack>
        </VStack>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton onCancel={handleClose} />
        <Dialog.ConfirmButton onConfirm={handleSave} />
      </Dialog.Footer>
    </Dialog>
  );
}

function Users({ orgId, groupPolicies }: Readonly<{ orgId: string; groupPolicies: MemberType[] }>) {
  const { users } = useGetOrganizationUsers(orgId);
  const { modifyUserInOrganization } = useModifyUserInOrganization();
  const { user, refreshSession, logout } = useAuthContext();
  const { errorToast } = useToast();
  const calculatePolicies = useCalculatePolicies();

  const modifyUserGroups = async (userId: string, groups: string[]) => {
    await modifyUserInOrganization(userId, orgId, groups).catch(errorToast);
    if (user?.dinaUserId === userId) {
      await refreshSession?.()
        .then((r) => calculatePolicies(r.groups ?? []))
        .catch((err) => {
          errorToast(err);
          logout?.().catch(noop);
        });
    }
  };

  const keyedUsers = useMemo(() => keyBy(users, 'id'), [users]);

  const [allUsers] = useUsers();

  const data: UserData[] = useMemo(() => {
    return allUsers.map((usr) => {
      const orgUser = keyedUsers[usr.mId];
      const isAdded = orgUser && !orgUser.isArchived && !orgUser.isDisabled;
      return {
        ...parseUser(usr),
        isAdded,
        isArchived: orgUser?.isArchived,
        isDisabled: orgUser?.isDisabled,
        groups: orgUser?.groups,
      };
    });
  }, [allUsers, keyedUsers]);

  const isSuperUser = useIsSuperUser();

  const groupMap = useMemo(() => keyBy(groupPolicies, 'mRefId'), [groupPolicies]);

  const meta: TableMeta<UserData> = useMemo(() => {
    return { disabled: !isSuperUser, userMap: groupMap as Dictionary<User> };
  }, [groupMap, isSuperUser]);

  const { table } = useDataTable({
    data,
    columns,
    meta,
    getRowId: (originalRow) => `${originalRow.mId}`,
  });

  return (
    <Box margin="8px 0 0 0">
      <DataTable table={table} />
      <GroupsSelector groupPolicies={groupPolicies} modifyUserGroups={modifyUserGroups} />
    </Box>
  );
}

export default Users;
