import { Editor, Element, Node, Path, Transforms } from 'slate';

import isList from 'components/editor/components/list/utils/isList';
import insertParagraph from 'components/editor/components/paragraph/utils/insertParagraph';
import isQuote from 'components/editor/components/quote/utils/isQuote';
import { elementTypes } from 'components/editor/constants/types';
import { CustomElement, CustomText } from 'types';

import isHeading from '../components/heading/utils/isHeading';
import { isEmptyParagraph } from '../components/paragraph/utils/isParagraph';
import isSection from '../components/sectionDivider/utils/isSection';
import unwrapSection from '../components/sectionDivider/utils/unwrapSection';
import { EditorVariant } from '../types';

import isEmptyEditor from './isEmptyEditor';

const { LIST_ITEM, UNORDERED_LIST, ORDERED_LIST, SECTION_DIVIDER, HEADING_TWO, PARAGRAPH } =
  elementTypes;

const { select, setNodes, removeNodes, insertNodes } = Transforms;
const { isElement } = Element;

type CustomNode = {
  children: CustomElement[];
};

const isTextEmpty = (node: CustomElement) => (node?.children?.[0] as CustomText)?.text === '';

const shouldUpdateNode = (node: CustomElement, type: string) =>
  node?.type !== type || isTextEmpty(node) !== node?.placeholder;

const normalizeSpecificRootNode = (
  editor: Editor,
  node: CustomNode,
  path: Path,
  variant: EditorVariant,
) => {
  const { children } = node;
  const numberOfChildren = children.length;
  const lastChild = children[numberOfChildren - 1];

  if (variant === 'notes') {
    if (shouldUpdateNode(children[0], HEADING_TWO)) {
      setNodes(
        editor,
        { type: HEADING_TWO, placeholder: isTextEmpty(children[0]) },
        { at: path.concat(0) },
      );
    }

    if (shouldUpdateNode(children[1], PARAGRAPH)) {
      setNodes(
        editor,
        { type: PARAGRAPH, placeholder: isTextEmpty(children[1]) },
        { at: path.concat(1) },
      );
    }

    if (children[2]?.type === PARAGRAPH && children[2]?.placeholder === true) {
      setNodes(editor, { placeholder: false }, { at: path.concat(2) });
    }
  }

  if (!lastChild) return insertParagraph(editor, { mode: 'highest', at: [0] });

  if (
    lastChild &&
    (editor.isVoid(lastChild) ||
      isSection(lastChild) ||
      isList(lastChild) ||
      isHeading(lastChild) ||
      isQuote(lastChild) ||
      !isEmptyParagraph(lastChild))
  ) {
    const focus = editor.selection?.focus;
    if (!focus) return;

    insertParagraph(editor, { mode: 'highest', at: [numberOfChildren] });

    return select(editor, focus);
  }
};

const normalizeList = (editor: Editor, path: Path) => {
  for (const [child, childPath] of Node.children(editor, path)) {
    if (!isElement(child)) return;
    if (child.type !== LIST_ITEM || child.type !== UNORDERED_LIST || child.type !== ORDERED_LIST)
      return setNodes(editor, { type: LIST_ITEM }, { at: childPath });
  }
};

const normalizeSection = (editor: Editor, path: Path) => {
  for (const [child, childPath] of Node.children(editor, path)) {
    if (!isElement(child)) return;
    if (child.type === SECTION_DIVIDER) {
      unwrapSection(editor, { at: childPath });
    }
  }
};

/**
 * Wraps editor with overridden normalization plugin functionalities
 *
 * @param editor SlateJS editor instance
 * @returns SlateJS editor instance
 */
const withNormalization = (editor: Editor, variant: EditorVariant) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    const isRoot = path.length === 0;

    const isEditorEmpty = isEmptyEditor(editor);

    if (isEditorEmpty) {
      select(editor, { offset: 0, path: [0, 0] });
    }

    if (isRoot) normalizeSpecificRootNode(editor, node as CustomNode, path, variant);

    if (!isElement(node)) return;
    const [firstNode] = node.children;

    if (editor.isVoid(node) && (firstNode as CustomText)?.text !== '') {
      removeNodes(editor, { at: path });
      return insertNodes(editor, { ...node, children: [{ text: '' }] });
    }

    if (isList(node)) normalizeList(editor, path);

    if (isSection(node)) normalizeSection(editor, path);

    return normalizeNode(entry);
  };

  return editor;
};

export default withNormalization;
